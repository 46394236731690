import React, { useEffect, useState, lazy, Suspense } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import Spinner from "./component/UI/LoadingSpiner/Spinner";
import "./App.css";

// Lazy-loaded components
const Home = lazy(() => import("./pages/Home"));
const AuthLogin = lazy(() => import("./component/Security/Authorization/AuthLogin"));
const About = lazy(() => import("./pages/About"));
const Services = lazy(() => import("./pages/Services"));
const AirportService = lazy(() => import("./pages/AirportServices"));
const ExecutiveChauffeurServices = lazy(() => import("./pages/ExecutiveChauffeurServices"));
const WeddingServices = lazy(() => import("./pages/WeddingServices"));
const WheelchairServices = lazy(() => import("./pages/WheelchairServices"));
const MinibusServices = lazy(() => import("./pages/MinibusServices"));
const HourlyServices = lazy(() => import("./pages/HourlyServices"));
const SchoolTravelServices = lazy(() => import("./pages/SchoolTravelServices"));
const Fleet = lazy(() => import("./pages/Fleet"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const Booking = lazy(() => import("./pages/Booking"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Terms = lazy(() => import("./pages/Terms"));
const ReadingTaxi = lazy(() => import("./pages/ReadingTaxi"));
const ReadingStationTaxi = lazy(() => import("./pages/ReadingStationTaxi"));
const TaxiReading = lazy(() => import("./pages/TaxiReading"));


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100); // Adjust or remove if unnecessary
  }, []);

  return (
      <div>
        {loading ? (
          <Spinner />
        ) : (
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/authlogin" element={<AuthLogin />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/services/airport" element={<AirportService />} />
              <Route path="/services/executive-chauffeur" element={<ExecutiveChauffeurServices />} />
              <Route path="/services/wedding" element={<WeddingServices />} />
              <Route path="/services/wheelchair" element={<WheelchairServices />} />
              <Route path="/services/minibus" element={<MinibusServices />} />
              <Route path="/services/hourly-service" element={<HourlyServices />} />
              <Route path="/services/school-transport" element={<SchoolTravelServices />} />
              <Route path="/fleet" element={<Fleet />} />
              <Route path="/booking" element={<Booking />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/reading-university-taxi" element={<ReadingTaxi />} />
              <Route path="/reading-station-taxi" element={<ReadingStationTaxi />} />
              <Route path="/taxi-reading" element={<TaxiReading />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Suspense>
        )}
      </div>
  );
}

export default App;
